<template>
  <div class="service-sub-container">
    <transition name="fade" mode="out-in">
      <div :key="subname">
        <!-- head -->
        <div class="subservice-head ">
          <div class="">{{ data[subname].head[0] }}</div>
          <span>{{ data[subname].head[1] }}</span>
        </div>

        <!-- subservice menu -->
        <div class="subservice-menu">
          <div class="row m-0 ">
            <router-link
              v-for="item in data[subname].menu"
              :key="item[1]"
              :to="{ name: 'ServiceContent', params: { contentname: item[1] } }"
              :class="{ active: currentRoute == item[1] }"
              class="col-6 col-sm-4 col-md-3 menu-item"
            >
              <div>
                {{ item[0] }}
              </div>
            </router-link>
          </div>
        </div>
        <div class="submenu-body">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ServiceSub",
  props: {
    subname: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentRoute: "",
      data: {
        wrinkle: {
          head: ["wrinkle", "ริ้วรอย ร่องลึก"],
          menu: [
            ["Botox", "botox"],
            ["E-Lifting", "elifting"],
            ["Filler", "filler"]
          ]
        },
        fat: {
          head: ["Fat and Lifting", "สลายไขมัน และ ยกกระชับ"],
          menu: [
            ["Melt Me", "meltMe"],
            ["Lipo Thread", "lipothread"],
            ["Lipo Fit", "lipofit"],
            ["Thread Lifting", "lifting"]
          ]
        },

        skin: {
          head: ["Skin and Anti-aging ", "บำรุงผิว และ เวชศาสตร์ชะลอวัย"],
          menu: [
            ["Made Collage", "collagen"],
            ["Filoga HA135", "ha135"],
            ["BCC Serum", "bcc"],
            ["Lumigen Acne", "lumigen"],
            ["สเต็มเซลล์จากเลือด", "prp"],
            ["เซลล์ใหม่จากรก", "placenta"],
            ["สเต็มเซลล์ตัวเต็มวัย", "growth"],
            ["สเต็มเซลล์", "stemcell"],
            ["วิตามินอาหารผิว", "skinfood"]
          ]
        },
        scar: {
          head: ["Acne scar", "หลุมสิว"],
          menu: [["EPN Course", "epn"]]
        },
        tech: {
          head: ["High Technology", "Hifu"],
          menu: [["ไฮฟูยกกระชับ", "hifu"]]
        },
        surgery: {
          head: ["Cosmetic Surgery", "ศัยกรรมความงาม"],
          menu: [
            ["ดูดไขมัน", "liposuction"],
            ["ศัลยกรรมจมูก", "phiniplasty"],
            ["ศัลยกรรมคาง", "chinaugment"],
            ["เติมไขมัน", "fattransfer"]
          ]
        }
      }
    };
  },
  watch: {
    $route: {
      handler(val) {
        if (val.name != "ServiceContent") {
          this.replaceRoute();
        }
      }
    }
  },
  mounted() {
    this.replaceRoute();
  },
  methods: {
    replaceRoute() {
      this.$router.replace({
        path: `/service/${this.subname}/${this.data[this.subname].menu[0][1]}`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@scss/service/service_sub";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
